.home {
  color: #fff;
  font-size: 1.6rem;
  text-align: center;
  /* display: flex; */
  margin-top: 10rem;
  font-weight: 300;
}

.home .name {
  margin-top: 1rem;
  font-size: 3rem;
}
.home .first-name {
  color: rgb(111, 174, 251);
}

.home .short-intro {
  font-size: 1.3rem;
  color: rgb(218, 218, 218);
  margin-top: 1rem;
}

.links {
  display: flex;
  margin-top: 2rem;
  justify-content: center;
  gap: 20px;
}
.links a img {
  width: 30px;
  height: 30px;
  filter: invert(85%);
  margin-top: 7px;
}
/* media queries */
@media only screen and (min-width: 38em) {
}

@media only screen and (min-width: 60em) {
}

@media only screen and (min-width: 90em) {
  .introduction {
    width: 50vw;
    margin-left: 10rem;
    text-align: left;
    margin-top: 18rem;
  }
  .introduction .btn {
    font-size: 1rem;
    /* margin-top: -3px; */
    /* padding: 7px; */
  }
  .introduction {
    font-size: 2rem;
  }
  .home .name {
    font-size: 3.5rem;
  }
  .links {
    justify-content: flex-start;
  }
  .links a img {
    margin-top: 14px;
  }
}
