.App {
  display: flex;
  width: 100vw;
  height: 100%;

  background: var(--bg-color);
}
.App .container {
  flex-grow: 1;
  padding: 0 60px;
}
.bg {
  position: absolute;
  display: block;
  background-image: url(./assets/background-image.jpg);
  width: 100vw;
  height: 100vh;
  min-height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: 50% 0;
  overflow: auto;
}
@media only screen and (min-width: 38em) {
}

@media only screen and (min-width: 90em) {
  .bg {
    position: absolute;
    display: block;
    background-image: url(./assets/40.jpg);
    width: 100vw;
    height: 100vh;
    min-height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-attachment: fixed;
    background-position: right;
    /* background-position: center; */
    overflow: auto;
  }
}
