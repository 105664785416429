.project-list {
  margin: 10rem auto;
  width: 75vw;
  height: 100%;
  text-align: center;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-gap: 30px;
}
.cards {
  margin: 0 auto;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 270px 330px 80px;
  grid-template-areas: 'image' 'text' 'stats';
  border-radius: 18px;
  background: #242526;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.6);
}
.cards-stats {
  grid-area: stats;
}
.cards-image {
  grid-area: image;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: 100%;
  height: 100%;
  /* padding: 0.7rem; */
  object-fit: contain;
  margin-top: 1rem;
}
.cards-text {
  grid-area: text;
  padding: 0.7rem;
  margin-top: -0.5rem;
  /* margin: 10px auto; */
  /* margin: 0.7rem; */
  /* padding-bottom: 2rem; */
  /* background-color: pink; */
}
.cards-text h2 {
  color: var(--primary-color);
  font-weight: 400;
  /* color: #fff; */
  text-align: center;
  margin: 0 10px;
  margin-bottom: 10px;
  font-size: 1.2rem;
}
.cards-text h3 {
  color: #fff;
  text-align: left;
  margin: 15px 10px 0 10px;
  font-size: 1.1rem;
  border-top: 1px solid #fff;
  padding-top: 10px;
}
.cards-text p {
  text-align: left;
  margin: 0 10px;
  color: rgb(225, 225, 225);
  font-size: 1.1rem;
  font-weight: 300;
}
.cards-stats {
  grid-area: stats;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background: rgba(0, 0, 0, 0.6);
}
.cards-stats .stat {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 1.1rem;
  color: white;
  padding: 1rem;
}
.stat:first-child {
  border-right: 1px solid gray;
}
.type {
  color: white;
  display: flex;
  gap: 10px;
}
.type .icon {
  margin-top: 3px;
}
.type:hover {
  color: var(--primary-color);
}
.cards:hover {
  transform: scale(1.1);
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.6);
}
.cards {
  transition: 0.5s ease;
  cursor: pointer;
  margin: 30px;
}
.logos {
  display: flex;
  margin-left: 10px;
  margin-top: 10px;
}
.logo {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.blockClick{
  pointer-events: none;
}
