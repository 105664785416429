@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;1,300&display=swap');

/* variables */
:root {
  --heading-color: #444;
  --text-color: #999;
  --primary-color: rgb(111, 174, 251);
  --highlight-color: #d13267;
  --hover-color: #aba4fe;
  --bg-color: #282c34;
  /* --bg-color: #2a2c30f7; */
  --border-color: linear-gradient(to left, #f0f0f0, #aee1f9);
}

/* base styles */
body {
  font-family: 'Lato', sans-serif;
  margin: 0;
  font-size: 1.1em;
  background: var(--bg-color);
}

ul,
li,
p,
h1,
h2,
h3,
h4 {
  margin: 0;
  padding: 0;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
/* layout */
.page-title {
  font-size: 1em;
  color: #fff;
  display: inline-block;
}
.btn {
  background: none;
  color: var(--primary-color);
  /* margin-top: 3.2rem; */
  padding: 8px 12px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1rem;
  border: 1px solid var(--primary-color);
}
.btn:hover {
  color: #fff;
  background-color: var(--primary-color);
}

/* forms */
label {
  display: block;
  margin: 24px auto;
  color: #fff;
}
label span {
  display: block;
  margin-bottom: 6px;
}
input,
textarea {
  padding: 8px 6px;
  font-size: 1em;
  color: #777;
  /* color: #fff; */
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 4px;
}
textarea {
  min-height: 160px;
}
.error {
  color: red;
  background: pink;
  border: 1px solid red;
  border-radius: 4px;
  padding: 8px;
  margin: 10px 0;
}
