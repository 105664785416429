footer {
  position: fixed;
  bottom: 1rem;
  width: 100vw;
  display: flex;
  font-size: 1rem;
  right: 1rem;
  color: #f0f0f0;
  justify-content: right;
}
